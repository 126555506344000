import React, { useState, Fragment, useEffect } from 'react';
import styles from './Transactions.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import {
	ColumnCustomers,
	CustomersData,
} from '../../types/TablesTypes/Customers';
import MenuOption from '../../components/MenuOption/MenuOption';
import StatusView from '../../components/StatusView/StatusView';
import TableHeader from '../../components/TableHeader/TableHeader';
import ViewIcon from '../../assets/images/Icons/view-details.svg';
import DeactivateIcon from '../../assets/images/Icons/deactivate-user.svg';
import { ReactComponent as LinkIcon } from '../../assets/images/Icons/link-icon.svg';
import customersApi from '../../api/customers';
import useApi from '../../hooks/useApi';
import client from '../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../constants/toastStyles';
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import CreateCustomer from '../../components/ModalContent/CreateCustomer/CreateCustomer';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';
import CustomerDetails from './CustomerDetails/CustomerDetails';
import { Dayjs } from 'dayjs';
import FilterModal from '../../components/filterConfig/FilterModal';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../utils/datefunction';

const tableCountry = [
	{ id: 1, name: 'Nigeria' },
	{ id: 2, name: 'Kenya' },
	{ id: 3, name: 'Ghana' },
	{ id: 3, name: 'South Africa' },
];

const Customers = () => {
	const [tableRow, setTableRow] = useState<any[]>();
	const [customers, setCustomers] = useState<any>('');
	const [customerId, setCustomerId] = useState<number | null>(null);
	const getCustomersApi = useApi(customersApi.getCustomers);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}
	const dispatch = useDispatch();
	const history = useHistory();

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	const { subsidiaryInfo } = useSelector(
		(state) => state.subsidiaryInfoReducer
	);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [email, setEmail] = useState('');
	const [country, setCountry] = useState('');
	const [bearer, setBearer] = useState(false);
	const [customergroup, setCustomergroup] = useState('');

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setEmail('');
		setCountry('');
		setCustomergroup('');
		setDropdown(false);
		setBearer(true);
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const CountryArray = [
		{ name: 'Nigeria' },
		{ name: 'Ghana' },
		{ name: 'Kenya' },
	];

	const filteredArray = [
		{
			name: 'Country',
			value: country,
			setValue: setCountry,
			selective: CountryArray,
		},
		{
			name: 'Customer Group',
			value: customergroup,
			setValue: setCustomergroup,
		},
	];

	console.log('subsidiaryInfo', subsidiaryInfo?.data?.user?.id);

	const fetchCustomers = async () => {
		country.replace(/-|\s/g, '');
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`customer/all/paginated?customergroup=${customergroup}&email=${email}&country=${country}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`
			);
			setCustomers(data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchCustomers();
	}, [bearer, email, pageNumber, rowsPerPage]);

	useEffect(() => {
		setPageNumber(customers?.currentPage || 1);
		setPreviousPage(customers?.previousPage);
		setNextPage(customers?.nextPage);
	}, [customers]);

	const menuOptions = [
		{
			id: 1,
			name: (id: number) => {
				return 'View customer details';
			},
			icon: ViewIcon,
			onclick: (
				customerEmail: string,
				e: React.SyntheticEvent,
				status: string,
				customerId: number
			) => {
				setCustomerId(customerId);
			},
		},
	];

	const dataCustomers = () => {
		const tempArr: CustomersData[] = [];
		customers.items
			?.slice(0)
			.reverse()
			.forEach((customer: any, index: number) => {
				return tempArr.push({
					// dateCreated: moment(customers?.dateCreated).format(
					//   "MMMM Do YYYY, h:mm a"
					// ),
					customerName: customer?.customerName,
					emailAddress: customer?.emailAddress,
					mobileNumber: customer?.mobileNumber,
					country: customer?.countryName,
					transactionCount: customer?.transactionCount,
					action: (
						<MenuOption
							menuContent={
								<div>
									{menuOptions.map((option: any) => (
										<div
											key={option?.id}
											onClick={(e) => {
												option?.onclick(
													customer?.emailAddress,
													e,
													customer?.status,
													customer?.id
												);
											}}
											className={styles.menuContent}>
											<img
												src={option?.icon}
												alt={option?.name(customer?.id)}
												width={24}
												height={24}
											/>
											<div className={styles.optionName}>
												{option?.name(customer?.status)}
											</div>
										</div>
									))}
								</div>
							}
						/>
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataCustomers());
	}, [customers?.items]);

	const itemLength = customers?.items?.length;
	return (
		<div style={{ minHeight: '100vh' }}>
			{!customerId ? (
				<>
					<TableHeader
						pageName='Customers'
						data={customers?.items}
						dataLength={customers?.totalItems}
						value={email}
						setValue={setEmail}
						dropdown={dropdown}
						setDropdown={setDropdown}
						placeHolder='Search by email'
						FilterComponent={
							<FilterModal
								eventDate={eventDate}
								setEventDate={setEventDate}
								dropdown={dropdown}
								setDropdown={setDropdown}
								setFromDate={setFromDate}
								setToDate={setToDate}
								fromDate={fromDate}
								toDate={toDate}
								setBearer={setBearer}
								clearHandler={clearHandler}
								filteredArray={filteredArray}
							/>
						}
					/>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel='Example Modal'
						style={customStyles}>
						<CreateCustomer
							fetchCustomers={fetchCustomers}
							closeModal={closeModal}
						/>
					</Modal>
					{getCustomersApi.loading ? (
						<div className={styles.loading} />
					) : (
						<PaginationTable
							data={tableRow ? tableRow : []}
							columns={ColumnCustomers ? ColumnCustomers : []}
							value={value}
							emptyPlaceHolder={
								customers?.totalItems == 0
									? 'You currently do not have any customers'
									: 'Loading...'
							}
							total={customers?.totalItems}
							totalPage={customers?.totalPages}
							pageNumber={pageNumber}
							setPageNumber={setPageNumber}
							nextPage={nextPage}
							setNextPage={setNextPage}
							previousPage={previousPage}
							setPreviousPage={setPreviousPage}
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
						/>
					)}
				</>
			) : (
				<CustomerDetails id={customerId} setId={setCustomerId} />
			)}
		</div>
	);
};

export default Customers;
