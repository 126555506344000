import React, { useState, Fragment, useEffect } from "react";
import styles from "./InitiateBankToBankPayouts.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import { ColumnBankToBankPayouts, BankToBankPayoutsData } from "../../../types/TablesTypes/Payouts";
import TableHeader from "../../../components/TableHeader/TableHeader";
import getPendingBankToBankPayouts from "../../../api/allPayouts";
import useApi from "../../../hooks/useApi";
import { numberWithCommas } from "../../../utils/formatNumber";
import moment from "moment";
import { customStyles } from "../../../constants/modalStyles";
import Modal from "react-modal";
import client from "../../../api/client";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";
import { useDispatch, useSelector } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { ToastErrorStyles } from "../../../constants/toastStyles";
import Filtered from "../../../components/filterComponent/Filtered";
import StatusView from "../../../components/StatusView/StatusView";
import FilterModal from "../../../components/filterConfig/FilterModal";
import { Dayjs } from "dayjs";
import { dateNow, endOfYear, sevenDaysAgo, startOfYear, thirtyDaysAgo } from "../../../utils/datefunction";
import PayoutMenu from "../../../components/MenuOption/PayoutMenu/PayoutMenu";
import InternalBankToBankPayout from "../../../components/ModalContent/InitiatePayout/InternalBankToBankPayout";

const InitiateBankToBankPayouts = ({ payout, cb }: any) => {
  const dispatch = useDispatch();

  const [payouts, setPayouts] = useState<any>();
  const [tableRow, setTableRow] = useState<any[]>();
  const getPendingBankToBankPayoutsApi = useApi(getPendingBankToBankPayouts.getPendingBankToBankPayouts)
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
	const [modalIsOpen, setIsOpen] = React.useState(false);
  const [status, setStatus] = useState<string>("");
  const [SelectedSearchColumn, setSelectedSearchColumn] = useState<string>("");
  const [SearchableColumns, setSearchableColumns] = useState<string>("");
  const [Keyword, setKeyword] = useState<string>("");
  //FILTERING STARTS
  const [eventDate, setEventDate] = useState("");
  const [fromDate, setFromDate] = useState<Dayjs | null | string>("");
  const [toDate, setToDate] = useState<Dayjs | null | string>("");
  const [bearer, setBearer] = useState(false);
  const [merchantId, setMerchantId] = useState("");
  const [recipientName, setRecipientName] = useState("");
  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  

	function closeModal() {
		setIsOpen(false);
	}
  
  //for initiate payout button

	const [modalIsOpenSingle, setIsOpenSingle] = React.useState({
		isOpened: false, data: null
	});
  function openModalSingle(data: any = null) {
		// console.log({ data })
		setIsOpenSingle({ isOpened: true, data: data });
	}

  function closeModalSingle() {
		setIsOpenSingle({ isOpened: false, data: null });
	}


  interface StatusViewProps {
    status: string;
  }

  //Date filter logic Starts
    useEffect(() => {
      if (eventDate === "today") {
        setFromDate(dateNow);
        setToDate(dateNow);
        // console.log("datenow:", dateNow);
      } else if (eventDate === "last7days") {
        setFromDate(sevenDaysAgo);
        setToDate(dateNow);
      } else if (eventDate === "last30days") {
        setFromDate(thirtyDaysAgo);
        setToDate(dateNow);
      } else if (eventDate === "oneyear") {
        setFromDate(startOfYear);
        setToDate(endOfYear);
      } else {
        setFromDate("");
        setToDate("");
      }
    }, [eventDate]);
  //Date filter logic Ends

  // Apply filters when merchantId or status is updated
    useEffect(() => {
      if (status) {
        setSearchableColumns("Status");
        setKeyword(status);
      }else if (recipientName) {
        setSearchableColumns("RecipientName");
        setKeyword(recipientName);
      } 
      else {
        setSearchableColumns("");
        setKeyword("");
      }
    }, [status, recipientName]);

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
    setSearchableColumns("");
    setKeyword('')
    setRecipientName('')
  };

  const filteredArray = [
    {
      name: "Reciepient Name",
      value: recipientName,
      setValue: setRecipientName,
    },
    {
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [
        { name: "Failed" },
        { name: "Awaiting Verification" },
        { name: "Pending" },
        { name: "Processing" },
        { name: "Initiated" },
        { name: "Successful" },
        { name: "Awaiting Approval" },
      ],
    },
  ];
  //FILTERING ENDS

  const fetchPayouts = async () => {
    dispatch(openLoader());
    try {
      // const response = await getAllPayouts.getAllPayouts({
        const response: any = await getPendingBankToBankPayouts.getPendingBankToBankPayouts({
        PageNumber: pageNumber,
        PageSize: rowsPerPage,
        StartDate: fromDate ? fromDate: '',
        EndDate: toDate ? toDate : '',
        Status: status || '', 
        Filter: merchantId || '',
        SearchableColumns: SearchableColumns || '',
        Keyword: Keyword || '',
      });

      const payoutData = response?.data?.data;
      setPayouts(payoutData);
      setBearer(false);
      setTotalItems(payoutData?.totalCount || 0);
    } catch (error) {
      dispatch(
        openToastAndSetContent({
          toastContent: "Error fetching payouts",
          toastStyles: ToastErrorStyles,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  useEffect(() => {
    fetchPayouts();
  }, [pageNumber, bearer,rowsPerPage]);

  interface StatusViewProps {
    status: string;
  }

  useEffect(() => {
    setPageNumber(payouts?.pageNumber || 1);
    setPreviousPage(payouts?.pageNumber - 1);
    setNextPage(payouts?.pageNumber + 1);
  }, [payouts]);

  const dataPayout = () => {
    const tempArr: BankToBankPayoutsData[] = [];
    payouts?.items
      ?.slice(0)
      .reverse()
      .forEach((payout: any, index: number) => {
        tempArr.push({
          dateInitiated: moment(payout?.dateInitiated).isValid()
          ? moment(payout?.dateInitiated).format('MMMM Do YYYY, h:mm a')
          : 'Invalid Date',
        
          recipientName: payout.recipientName,
          bankName: payout?.bankName,
          subsidiaryId: payout?.subsidiaryId,
          accountNumber: payout?.accountNumber,
          currency: payout.debitCurrency,
          type: payout?.type,
          transferType: payout?.transferType,
          amount: `${payout?.debitCurrency} ${numberWithCommas(
            payout?.amount
          )}`,
          fee: `${payout?.debitCurrency} ${numberWithCommas(payout?.fee)}`,
          status: (
            <StatusView
              status={payout?.status}
              green={"Approved || Successful"}
              red={"Rejected || Failed || Declined"}
              orange={"Processing || Pending"}
              blue={"Awaiting Verification"}
              purple="Awaiting Approval"
            />
          ),
          action: <PayoutMenu data={payout} />,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    const newTableRow = dataPayout();
    setTableRow(newTableRow);
}, [payouts]);

  
  return (
    <Fragment>
      <TableHeader
        pageName="Initiate Bank-To-Bank Payouts"
        dataLength={payouts?.totalItems}
        data={payouts?.items}
        value={value}
        setValue={setValue}
        dropdown={dropdown}
        placeHolder="Search Merchant name"
        setDropdown={setDropdown}
        fetchDataHandler={fetchPayouts}
        FilterComponent={
            <FilterModal
              eventDate={eventDate}
              setEventDate={setEventDate}
              dropdown={dropdown}
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              fromDate={fromDate}
              toDate={toDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}       
            />
          }
      />

      {getPendingBankToBankPayoutsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <>
        <div className={styles.modalselect}>
            <span>
              <button
                className={styles.button}
                onClick={() => openModalSingle()}
              >
                Initiate Transfer
              </button>
            </span>
        </div>
        
        <PaginationTable
          data={tableRow ? tableRow : []}
          value={value}
          columns={ColumnBankToBankPayouts ? ColumnBankToBankPayouts : []}
          emptyPlaceHolder="You currently do not have any payouts"
          total={payouts?.totalCount}
          totalPage={payouts?.totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          nextPage={nextPage}
          setNextPage={setNextPage}
          previousPage={previousPage}
          setPreviousPage={setPreviousPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
        {/* //MODAL FOR Payout */}
        <Modal
          isOpen={modalIsOpenSingle.isOpened}
          onRequestClose={closeModalSingle}
          contentLabel='Example Modal'
          style={customStyles}>
          <InternalBankToBankPayout closeModal={closeModalSingle}  />
        </Modal>


        </>
      )}
    </Fragment>
  );
};

export default InitiateBankToBankPayouts;
