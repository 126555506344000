import { GET_CURRENCIES, GET_MCCS, GET_SUBSIDIARY_GROUPS } from "../../actions/constants";

const initialNavbarState = {
	currency: 'NGN',
	mccs: [],
	currencies: [],
	subsidiaryGroups: [],
	subsidiaryStatuses: [
		{ name: 'Active', value: 'Active' },
		{ name: 'Dormant', value: 'Dormant' },
		{ name: 'In-Review', value: 'In-Review' },
		{ name: 'Decline', value: 'Decline' },
	],
};

export const appSettingsReducer = (state = initialNavbarState, action) => {
	switch (action.type) {
		case 'CHANGE_CURRENCY': {
			return {
				...state,
				currency: action.currency,
			};
		}
		case GET_MCCS: {
			return {
				...state,
				mccs: action.mccs,
			};
		}
		case GET_SUBSIDIARY_GROUPS: {
			return {
				...state,
				subsidiaryGroups: action.subsidiaryGroups,
			};
		}
		case GET_CURRENCIES: {
			return {
				...state,
				currencies: action.currencies,
			};
		}
		default: {
			return state;
		}
	}
};

export default appSettingsReducer;