import React, { useState, useEffect } from 'react';
import styles from './Business.module.scss';
import Grid from '@material-ui/core/Grid';
import Card from '../../components/Card/Card';
import TimerIcon from '../../assets/images/Icons/timer.svg';
import SettlementIcon from '../../assets/images/Icons/settlement-icon.svg';
import BalanceIcon from '../../assets/images/Icons/balance-card-icon.svg';
import ChargebacksIcon from '../../assets/images/Icons/chargeback.svg';
import CardIcon from '../../assets/images/Icons/card-icon.svg';
import client from '../../api/client';
import {
	ToastErrorStyles,
} from '../../constants/toastStyles';
import { useDispatch, useSelector } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';
import useApi from '../../hooks/useApi';
import customersApi from '../../api/customers';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ReactComponent as LinkIcon } from '../../assets/images/Icons/link-icon.svg';
import Trans from './Trans';
import Cust from './Cust';
import { useHistory, useParams } from 'react-router-dom';

interface idTypes {
	id: number;
	name: string;
	modalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const BusinessModuleDetails = () => {

	//view options dropdown
	const [istoggleDropdownOpen, setIstoggleDropdownOpen] = useState(false);
	const toggleDropdown = () => {
		setIstoggleDropdownOpen(!istoggleDropdownOpen);
	};


	const history: any = useHistory();
	const params: any = useParams();
	const id = params.id;
	const name = id;
	const [transactionSummaryDetail, settransactionSummaryDetail] = useState<any>(
		[]
	);
	const [balancesChargebacks, setbalancesChargebacks] = useState<any>([]);
	const [tableCustomers, setTableCustomers] = useState<any[]>();
	const getCustomersApi = useApi(customersApi.getCustomers);
	const { currency } = useSelector((state) => state.appSettingsReducer);

	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	const [filterParam, setFilterParam] = useState<any>('year');
	const [inflowOutflowData, setInflowOutflowData] = useState<any>();

	const fetchInflow = async () => {
		const { data }: any = await client.get(
			`metric/inflow/outflow?type=${filterParam}&businessid=${id}&currency=${currency}`
		);
		setInflowOutflowData(data?.data);
	};

	const openBusinessAction = async (businessId: any, page: any) => {
		// console.log({ business })
		// setDetails(business.id);
		// setBusinessName(business.businessName);
		history.push(`/dashboard/accounts/${businessId}/${page}`)
	};
	useEffect(() => {
		fetchInflow();
	}, [filterParam, currency]);

	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}

	useEffect(() => {
		dispatch(openLoader());
		setIsLoading(true);
		client
			.get(`metric/transaction/summary?businessid=${id}&currency=${currency}`)
			.then((res: any) => {
				settransactionSummaryDetail(res?.data?.data);
				setIsLoading(false);
				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());
				setIsLoading(false);
				if (error.response) {
					const message = error?.response?.data?.message;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	}, [currency]);

	useEffect(() => {
		dispatch(openLoader());
		setIsLoading(true);
		client
			.get(`metric/balances/chargebacks?businessid=${id}&currency=${currency}`)
			.then((res: any) => {
				setbalancesChargebacks(res?.data?.data);
				setIsLoading(false);
				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());
				setIsLoading(false);
				if (error.response) {
					const message = error?.response?.data?.message;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	}, [currency]);

	const count = transactionSummaryDetail?.count;
	const volume = transactionSummaryDetail?.volume;
	const settlements = transactionSummaryDetail?.settlements;
	const balance = balancesChargebacks?.availableBalance;
	const ledger_balance = balancesChargebacks?.ledgerBalance;
	const chargebacks = balancesChargebacks.chargebacks;
	const balance_change =
		balancesChargebacks.lastWeekAvailableBalancePercentChange;
	const ledger_balance_change =
		balancesChargebacks.lastWeekLedgerBalancePercentChange;
	const chargebacks_change =
		balancesChargebacks.lastWeekChargebacksPercentChange;
	const settlement_change =
		transactionSummaryDetail?.lastWeekSettlementsPercentChange;
	const volume_change =
		transactionSummaryDetail?.lastWeekVolumePercentChange;
	const count_change = transactionSummaryDetail?.lastWeekCountPercentChange;

	return (
		<div>
			{isLoading ? (
				<div />
			) : (
				<div className={styles.dashbordContainer}>
					<div className={styles.transactionsHeader}>
						<div
							style={{
								color: '#211F01',
								display: 'inline',
								cursor: 'pointer',
								fontWeight: '800px',
							}}
							onClick={() => history.goBack()}>
							<ArrowBackIosIcon sx={{ fontSize: '20px' }} /> {name} ID
						</div>
						{/* new dropdown option  */}
						{/* <div className={styles.businessActionButtons}> */}
						<div className={styles.dropdown}>
							<div className={styles.dropbtnBox}> <button className={styles.dropbtn} onClick={toggleDropdown}> View Options </button> </div>
							{istoggleDropdownOpen && (
								<div className={styles.dropdownContent}>
									<div className={styles.dropdownContentText} onClick={() => { openBusinessAction(id, 'funding-history'); setIsOpen(false); }} > Funding History </div>
									<div className={styles.dropdownContentText} onClick={() => { openBusinessAction(id, 'wallet-history'); setIsOpen(false); }} > Balance History </div>
									<div className={styles.dropdownContentText} onClick={() => { openBusinessAction(id, 'rolling-reserve'); setIsOpen(false); }} > Rolling Reserve </div>
									<div className={styles.dropdownContentText} onClick={() => { openBusinessAction(id, 'rolling-reserve'); setIsOpen(false); }} > Get Payment Link </div>
									<div className={styles.dropdownContentText} onClick={() => { openBusinessAction(id, 'balance-operation'); setIsOpen(false); }} > Balance Operation </div>
								</div>
							)}
						</div>
						{/* </div> */}


						{/* old menu view option  */}
						{/* <div className={styles.businessActionButtons}>
							<div className={styles.createLink} onClick={() => openBusinessAction(id, 'funding-history')}>
								//<LinkIcon className={styles.link} />
								View Funding History
							</div>
							<div className={styles.createLink} onClick={() => openBusinessAction(id, 'wallet-history')}>
								<LinkIcon className={styles.link} />
								View Balance History
							</div>
							<div className={styles.createLink} onClick={() => openBusinessAction(id, 'rolling-reserve')}>
								<LinkIcon className={styles.link} />
								View Rolling Reserve
							</div>
						</div> */}

					</div>
					{transactionSummaryDetail.loading ? (
						<div className={styles.loading} />
					) : (
						<>
							<Grid container spacing={2}>
								<Grid item md={4} xs={12}>
									<Card
										icon={TimerIcon}
										percentage={count_change}
										amount={count}
										transactionType='Total transaction count'
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={CardIcon}
										percentage={volume_change}
										amount={volume}
										transactionType='Total transaction balance'
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={SettlementIcon}
										percentage={settlement_change}
										amount={settlements}
										transactionType='Total transaction settlements'
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={BalanceIcon}
										percentage={balance_change}
										amount={balance}
										transactionType='Available balance'
									// info_icon={InfoIcon}
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={CardIcon}
										percentage={ledger_balance_change}
										amount={ledger_balance}
										transactionType='Ledger balance'
									/>
								</Grid>
								<Grid item md={4} xs={12}>
									<Card
										icon={ChargebacksIcon}
										percentage={chargebacks_change}
										amount={chargebacks}
										transactionType='Chargebacks'
									/>
								</Grid>
								{/* <Grid item xs={12}>
									<div className={styles.dashboardChart}>
										<Chart
											inflowOutflow={inflowOutflowData}
											filterParam={filterParam}
											setFilterParam={setFilterParam}
										/>
									</div>
								</Grid> */}
							</Grid>
							<div className={styles.mt1}>
								<Trans id={id} />
								{/* {console.log(`id from bdm${id}`)} */}
							</div>
							{/* <div className={styles.mt1}>
								<Subs id={id} name={name} />
							</div> */}

							<div className={styles.mt1}>
								<Cust id={id} name={name} />
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default BusinessModuleDetails;
