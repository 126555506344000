import React, { useState, Fragment, useEffect } from 'react';
import styles from './CreditSummaryTable.module.scss';
import PaginationTable from '../../../../components/table/pagination-table';
import { ColumnCredit, CreditData } from '../../../../types/TablesTypes/Credit';
import MenuOption from '../../../../components/MenuOption/MenuOption';
import StatusView from '../../../../components/StatusView/StatusView';
import TableHeader from '../../../../components/TableHeader/TableHeader';
import ViewIcon from '../../../../assets/images/Icons/view-details.svg';
import DeactivateIcon from '../../../../assets/images/Icons/deactivate-user.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/images/Icons/link-icon.svg';
import businessApi from '../../../../api/businesses';
import useApi from '../../../../hooks/useApi';
import client from '../../../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../../redux/actions/toast/toastActions';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../../constants/toastStyles';
import Modal from 'react-modal';
import { customStyles } from '../../../../constants/modalStyles';
import CreateCustomer from '../../../../components/ModalContent/CreateCustomer/CreateCustomer';
import { useHistory } from 'react-router-dom';
import {
	closeLoader,
	openLoader,
} from '../../../../redux/actions/loader/loaderActions';
import moment from 'moment';
import Mocked from './mocked.json';
import dayjs, { Dayjs } from 'dayjs';
import FilterModal from '../../../../components/filterConfig/FilterModal';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../../../utils/datefunction';

const tableStatus = [
	{ id: 1, name: 'New' },
	{ id: 2, name: 'Pending-Verification' },
];

const CreditSummaryTable = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState<any[]>();

	const [credit, setCredit] = useState<any>();
	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [paymentreference, setPaymentReference] = useState('');
	const [orderreference, setOrderReference] = useState('');
	const [status, setStatus] = useState('');
	const [bearer, setBearer] = useState(false);

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setPaymentReference('');
		setOrderReference('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray = [
		{
			name: 'Payment Reference',
			value: paymentreference,
			setValue: setPaymentReference,
		},
		{
			name: 'Order Reference',
			value: orderreference,
			setValue: setOrderReference,
		},
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			selective: [
				{ name: 'Successful' },
				{ name: 'Initiated' },
				{ name: 'Failed' },
				{ name: 'Reversed' },
			],
		},
	];

	const fetchCredit = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`wallet/transactions?status=successful&product=Collection&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`
			);
			setCredit(data);
			dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchCredit();
	}, [bearer, pageNumber, rowsPerPage]);

	const menuOptions = [
		{
			id: 1,
			name: (id: number) => {
				return 'View credit details';
			},
			icon: ViewIcon,
			onclick: (businessId: number) => {
				history.push(`/dashboard/wallets/credit/${businessId}`);
			},
		},
	];

	const BusiComp = ({ logo, name }: { logo: string; name: string }) => {
		return (
			<div className={styles.busiCompWrapper}>
				<div className={styles.busiCompWrapperImg}>
					<img src={logo} alt='' />
				</div>
				<p className={styles.busiCompWrapperp}>{name}</p>
			</div>
		);
	};

	const dataCredit = () => {
		const tempArr: CreditData[] = [];
		credit?.data?.items
			?.slice(0)
			.reverse()
			.forEach((business: any, index: number) => {
				return tempArr.push({
					paymentType: business.paymentType,
					businessName: business.businessName,
					amount: business?.totalAmount,
					transactionReference: business?.transactionReference,
					date: business?.dateCreated,
					action: (
						<MenuOption
							menuContent={
								<div>
									{menuOptions.map((option: any) => (
										<div
											key={option?.id}
											onClick={(e) => {
												option?.onclick(business?.id);
											}}
											className={styles.menuContent}>
											<img
												src={option?.icon}
												alt={option?.name(business?.id)}
												width={24}
												height={24}
											/>
											<div className={styles.optionName}>
												{option?.name(business?.businessName)}
											</div>
										</div>
									))}
								</div>
							}
						/>
					),
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataCredit());
	}, [credit?.data?.items]);

	return (
		<div style={{ marginTop: '38px' }}>
			<TableHeader
				pageName='Credit'
				data={credit?.data?.items}
				dataLength={credit?.data?.page?.total}
				value={value}
				setValue={setValue}
				dropdown={dropdown}
				setDropdown={setDropdown}
				searchfn={false}
				FilterComponent={
					<FilterModal
						eventDate={eventDate}
						setEventDate={setEventDate}
						dropdown={dropdown}
						setDropdown={setDropdown}
						setFromDate={setFromDate}
						setToDate={setToDate}
						fromDate={fromDate}
						toDate={toDate}
						setBearer={setBearer}
						clearHandler={clearHandler}
						filteredArray={filteredArray}
					/>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ColumnCredit ? ColumnCredit : []}
				emptyPlaceHolder={
					credit?.data?.page?.total == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				value={value}
				total={credit?.data?.page?.totalItems}
				totalPage={credit?.data?.page?.totalPages}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
			/>
		</div>
	);
};

export default CreditSummaryTable;
