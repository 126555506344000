import React, { useState, Fragment, useEffect } from "react";
import styles from "./InitiatePayouts.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import { ColumnPayouts, PayoutsData } from "../../../types/TablesTypes/Payouts";
import TableHeader from "../../../components/TableHeader/TableHeader";
import getAllPayouts from "../../../api/allPayouts";
import useApi from "../../../hooks/useApi";
import { numberWithCommas } from "../../../utils/formatNumber";
import moment from "moment";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import { customStyles } from "../../../constants/modalStyles";
import Modal from "react-modal";
// import InitiatePayout from "../../components/ModalContent/InitiatePayout/InitialPayout";
import client from "../../../api/client";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { ToastErrorStyles } from "../../../constants/toastStyles";
// import PayoutMenu from "../../components/MenuOption/PayoutMenu/PayoutMenu";
import Filtered from "../../../components/filterComponent/Filtered";
import { menuContent } from "../../../utils/menuContent";
import StatusView from "../../../components/StatusView/StatusView";
import FilterModal from "../../../components/filterConfig/FilterModal";
import { Dayjs } from "dayjs";
import { dateNow, endOfYear, sevenDaysAgo, startOfYear, thirtyDaysAgo } from "../../../utils/datefunction";
import PayoutMenu from "../../../components/MenuOption/PayoutMenu/PayoutMenu";
import { table } from "console";
import PayoutVerificationModal from "../../../components/ModalContent/PayoutVerificationModal/PayoutVerificationModal";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from "@material-ui/core";
import { ErrorMessage, Field } from "formik";
import SelectWrapper from "../../../components/Select";
import InitiatePayout from "../../../components/ModalContent/InitiatePayout/InitialPayout";
import MerchantToMerchant from "../../../components/ModalContent/InitiatePayout/MerchantToMerchantPayout";
import InternalWalletToMerchantPayout from "../../../components/ModalContent/InitiatePayout/InternalWalletToMerchantPayout";
import MerchantToBankPayout from "../../../components/ModalContent/InitiatePayout/MerchantToBankPayout";
import MerchantToInternalWalletPayout from "../../../components/ModalContent/InitiatePayout/MerchantToInternalWalletPayout";

const InitiatePayouts = ({ payout, cb }: any) => {
  const dispatch = useDispatch();

  const [payouts, setPayouts] = useState<any>();
  const [tableRow, setTableRow] = useState<any[]>();
  const getPayoutsApi = useApi(getAllPayouts.getAllPayouts);
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
	const [modalIsOpen, setIsOpen] = React.useState(false);
  const [status, setStatus] = useState<string>("");
  const [SelectedSearchColumn, setSelectedSearchColumn] = useState<string>("");
  const [SearchableColumns, setSearchableColumns] = useState<string>("");
  const [Keyword, setKeyword] = useState<string>("");
  //FILTERING STARTS
  const [eventDate, setEventDate] = useState("");
  const [fromDate, setFromDate] = useState<Dayjs | null | string>("");
  const [toDate, setToDate] = useState<Dayjs | null | string>("");
  const [bearer, setBearer] = useState(false);
  const [batchId, setBatchId] = useState<number | null>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const [accountNumber, setAccountNumber] = useState("");
  const [payoutReference, setPayoutReference] = useState("");
  const [subsidiaryId, setSubsidiaryId] = useState<number | null>(null);
  const [subsidiaryName, setSubsidiaryName] = useState("");
  const [merchantId, setMerchantId] = useState("");
  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  

	function closeModal() {
		setIsOpen(false);
	}

  //for initiate payout button

  const paymeth = ['Internal Wallet To Merchant Wallet', 'Merchant To Bank', 'Merchant To Internal Wallet', 'Merchant to Merchant']
  // Initialize the state for selected currency
  const [selectedPaymeth, setSelectedPaymeth] = useState(''); // Or set default to paymeth[0] if you want a pre-selected value
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Initially true to disable the button
  
  const handlePaymeth = (event: any) => {
    setSelectedPaymeth(event.target.value);
    setIsButtonDisabled(event.target.value === ''); // Enable button if a value is selected
  };

	const [modalIsOpenSingle, setIsOpenSingle] = React.useState({
		isOpened: false, data: null
	});
  function openModalSingle(data: any = null) {
		console.log({ data })
		setIsOpenSingle({ isOpened: true, data: data });
	}

  function closeModalSingle() {
		setIsOpenSingle({ isOpened: false, data: null });
	}

  const renderPayoutComponent = () => {
    switch (selectedPaymeth) {
      case 'Merchant to Merchant':
        return <MerchantToMerchant closeModal={closeModalSingle} />;
      case 'Internal Wallet To Merchant Wallet':
        return <InternalWalletToMerchantPayout closeModal={closeModalSingle} />;
      case 'Merchant To Bank':
        return <MerchantToBankPayout closeModal={closeModalSingle} />;
      case 'Merchant To Internal Wallet':
        return <MerchantToInternalWalletPayout closeModal={closeModalSingle} />;
      default:
        return null; // or you can return a default component or message
    }
  };

  
  //end payout button

  interface StatusViewProps {
    status: string;
  }

  //Date filter logic Starts
    useEffect(() => {
      if (eventDate === "today") {
        setFromDate(dateNow);
        setToDate(dateNow);
        // console.log("datenow:", dateNow);
      } else if (eventDate === "last7days") {
        setFromDate(sevenDaysAgo);
        setToDate(dateNow);
      } else if (eventDate === "last30days") {
        setFromDate(thirtyDaysAgo);
        setToDate(dateNow);
      } else if (eventDate === "oneyear") {
        setFromDate(startOfYear);
        setToDate(endOfYear);
      } else {
        setFromDate("");
        setToDate("");
      }
    }, [eventDate]);
  //Date filter logic Ends

  // Apply filters when merchantId or status is updated
    useEffect(() => {
      if (status) {
        setSearchableColumns("Status");
        setKeyword(status);
      } else {
        setSearchableColumns("");
        setKeyword("");
      }
    }, [status]);

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
    setSearchableColumns("");
  };

  const filteredArray = [
    {
      name: "Merchant Id",
      value: merchantId,
      setValue: setMerchantId,
    },
    {
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [
        { name: "Failed" },
        { name: "Awaiting Verification" },
        { name: "Pending" },
        { name: "Processing" },
        { name: "Initiated" },
        { name: "Successful" },
        { name: "Awaiting Approval" },
      ],
    },
  ];
  //FILTERING ENDS

  const fetchPayouts = async () => {
    dispatch(openLoader());
    try {
      // const response = await getAllPayouts.getAllPayouts({
        const response: any = await getAllPayouts.getAllPayouts({
        PageNumber: pageNumber,
        PageSize: rowsPerPage,
        StartDate: fromDate ? fromDate: '',
        EndDate: toDate ? toDate : '',
        Status: status || '', 
        Filter: merchantId || '',
        SearchableColumns: SearchableColumns || '',
        Keyword: Keyword || '',
      });

      const payoutData = response?.data?.data;
      setPayouts(payoutData);
      setBearer(false);
      setTotalItems(payoutData?.totalCount || 0);
    } catch (error) {
      dispatch(
        openToastAndSetContent({
          toastContent: "Error fetching payouts",
          toastStyles: ToastErrorStyles,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  useEffect(() => {
    fetchPayouts();
  }, [pageNumber, bearer,rowsPerPage]);

  interface StatusViewProps {
    status: string;
  }

  useEffect(() => {
    setPageNumber(payouts?.pageNumber || 1);
    setPreviousPage(payouts?.pageNumber - 1);
    setNextPage(payouts?.pageNumber + 1);
  }, [payouts]);

  const dataPayout = () => {
    const tempArr: PayoutsData[] = [];
    payouts?.items
      ?.slice(0)
      .reverse()
      .forEach((payout: any, index: number) => {
        tempArr.push({
          dateInitiated: moment(payout?.dateInitiated).isValid()
          ? moment(payout?.dateInitiated).format('MMMM Do YYYY, h:mm a')
          : 'Invalid Date',
        
          recipientName: payout.recipientName,
          merchantName: payout?.merchantName,
          subsidiaryId: payout?.subsidiaryId,
          accountNumber: payout?.accountNumber,
          currency: payout.debitCurrency,
          type: payout?.type,
          transferType: payout?.transferType,
          amount: `${payout?.debitCurrency} ${numberWithCommas(
            payout?.amount
          )}`,
          fee: `${payout?.debitCurrency} ${numberWithCommas(payout?.fee)}`,
          status: (
            <StatusView
              status={payout?.status}
              green={"Approved || Successful"}
              red={"Rejected || Failed || Declined"}
              orange={"Processing || Pending"}
              blue={"Awaiting Verification"}
              purple="Awaiting Approval"
            />
          ),
          action: <PayoutMenu data={payout} />,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    const newTableRow = dataPayout();
    setTableRow(newTableRow);
}, [payouts]);

  
  return (
    <Fragment>
      <TableHeader
        pageName="Initiate Payouts"
        dataLength={payouts?.totalItems}
        data={payouts?.items}
        value={value}
        setValue={setValue}
        dropdown={dropdown}
        placeHolder="Search Merchant name"
        setDropdown={setDropdown}
        fetchDataHandler={fetchPayouts}
        FilterComponent={
            <FilterModal
              eventDate={eventDate}
              setEventDate={setEventDate}
              dropdown={dropdown}
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              fromDate={fromDate}
              toDate={toDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              filteredArray={filteredArray}       
            />
          }
      />

      {getPayoutsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <>
        <div className={styles.modalselect}>
          <FormControl variant="outlined" size="small" margin="normal">
            <InputLabel id="Transfer-select-label">Select Transfer Type</InputLabel>
            <Select
              labelId="Transfer-select-label"
              id="Transfer-select"
              value={selectedPaymeth}
              onChange={handlePaymeth}
              label="Select Transfer"
              className={styles.select}
              MenuProps={{
                PaperProps: {
                  style: {
                    zIndex: 9999,
                  },
                },
              }}
            >
              {paymeth.map((method, index) => (
                <MenuItem key={index} value={method}>
                  {method}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Tooltip with disabled button */}
          <Tooltip
            title={isButtonDisabled ? "Please select a transfer type to proceed" : ""}
            arrow
            disableHoverListener={!isButtonDisabled}
          >
            <span>
              <button
                className={`${styles.button} ${isButtonDisabled ? styles.disabledButton : styles.enabledButton}`}
                onClick={() => openModalSingle()}
                disabled={isButtonDisabled}
              >
                Initiate Transfer
              </button>
            </span>
          </Tooltip>
        </div>
        
        <PaginationTable
          data={tableRow ? tableRow : []}
          value={value}
          columns={ColumnPayouts ? ColumnPayouts : []}
          emptyPlaceHolder="You currently do not have any payouts"
          total={payouts?.totalCount}
          totalPage={payouts?.totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          nextPage={nextPage}
          setNextPage={setNextPage}
          previousPage={previousPage}
          setPreviousPage={setPreviousPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
        {/* //MODAL FOR Payout */}
        <Modal
          isOpen={modalIsOpenSingle.isOpened}
          onRequestClose={closeModalSingle}
          contentLabel='Example Modal'
          style={customStyles}>
          {renderPayoutComponent()}
          {/* <FeesModal data={modalIsOpenSingle.data} closeModal={closeModalSingle} cb={fetchBusinesses} /> */}
        </Modal>


        </>
      )}
    </Fragment>
  );
};

export default InitiatePayouts;
